


































































































































import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import { setNumbering } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataResponseGetListInternalContractSo,
  DeliveryOrderData,
} from "@/models/interface/salesOrder.interface";
import { salesOrderServices } from "@/services/salesorder.service";
import Vue from "vue";

enum REFERENCE_TYPES_VALUE {
  IC = "IC",
  DO = "DO",
}

const REFERENCE_TYPES: Option[] = [
  { value: REFERENCE_TYPES_VALUE.IC, label: "lbl_internal_contract", key: 0 },
  { value: REFERENCE_TYPES_VALUE.DO, label: "lbl_delivery_order", key: 1 },
];

export default Vue.extend({
  name: "CetakSerahTerima",
  components: {
    SelectCustomer,
  },
  data() {
    this.getDeliveryOrderNumber = debounceProcess(
      this.getDeliveryOrderNumber,
      200
    );
    this.getInternalContractNumber = debounceProcess(
      this.getInternalContractNumber,
      200
    );
    return {
      REFERENCE_TYPES,
      form: this.$form.createForm(this, { name: "bastCetakSerahTerima" }),
      page: 1,
      limit: 10,
      params: {} as RequestQueryParamsModel,
      totalData: 0,
      loading: {
        find: false,
        do: false,
        icNumber: false,
        download: false,
        print: false,
      },
      columnsTable: [
        {
          title: this.$t("lbl_number_short"),
          dataIndex: "no",
          key: "no",
          width: 110,
          scopedSlots: { customRender: "no" },
        },
        {
          title: this.$t("lbl_do_number"),
          dataIndex: "doNumber",
          key: "doNumber",
          width: 170,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_do_date"),
          dataIndex: "doDate",
          key: "doDate",
          width: 170,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_transaction_number"),
          dataIndex: "transactionNumber",
          key: "transactionNumber",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_customer_name"),
          dataIndex: "customerName",
          key: "customerName",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_qty"),
          dataIndex: "qty",
          key: "qty",
          width: 150,
          scopedSlots: { customRender: "isQty" },
        },
        {
          title: this.$t("lbl_action"),
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
          button: ["view"],
          width: 120,
          align: "center",
          fixed: "right",
        },
      ],
      formRules: {
        deliveryOrderNumber: {
          label: "lbl_delivery_order_number",
          name: "deliveryOrderNumber",
          placeholder: "lbl_delivery_order_number_placeholder",
          decorator: [
            "deliveryOrderNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        internalContractNumber: {
          label: "lbl_internal_contract_number",
          name: "internalContractNumber",
          placeholder: "lbl_internal_contract_number_placeholder",
          decorator: [
            "internalContractNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        referenceType: {
          label: "lbl_reference_type",
          name: "referenceType",
          placeholder: "lbl_reference_type_placeholder",
          decorator: [
            "referenceType",
            {
              initialValue: REFERENCE_TYPES_VALUE.DO,
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        customerName: {
          label: "lbl_customer_name",
          name: "customerName",
          placeholder: "lbl_customer_name_placeholder",
          decorator: [
            "customerName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      dataSource: [] as any,
      dataDeliveryOrderNumber: [] as DeliveryOrderData[],
      dataInternalContractNumber: [] as DataResponseGetListInternalContractSo[],
      customerId: "" as string,
      referenceType: REFERENCE_TYPES_VALUE.DO,
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 4 },
        wrapperCol: { span: 8 },
      };
    },
    isTypeIC(): boolean {
      return this.referenceType === REFERENCE_TYPES_VALUE.IC;
    },
    isTypeDO(): boolean {
      return this.referenceType === REFERENCE_TYPES_VALUE.DO;
    },
  },
  created() {
    this.getDeliveryOrderNumber();
    this.getInternalContractNumber();
  },
  methods: {
    handleChangeReferenceType(value: REFERENCE_TYPES_VALUE): void {
      this.referenceType = value;
    },
    handleChangeDo(docNumber = ""): void {
      const doNumber = this.dataDeliveryOrderNumber.find(
        item => item.documentNumber === docNumber
      );
      if (!doNumber) return;
      this.customerId = doNumber.customerId;
      this.form.setFieldsValue({
        customerName: doNumber.customerName,
      });
    },
    handleChangeCustomer(value = ""): void {
      this.customerId = value;
      this.form.resetFields(["deliveryOrderNumber"]);
      this.getDeliveryOrderNumber();
    },
    async responseViewTable(response) {
      if (response.data.doNumber) {
        this.$router.push(`/sales/serah-terima/print/${response.data.id}`);
      } else {
        const params = {
          search: `documentNo~*${response.data.transactionNumber}*`,
        };
        const resIc = await salesOrderServices.getListInternalContract(params);
        const responseIc = await salesOrderServices.getListBastByIc(
          resIc.data[0].id
        );
        localStorage.setItem("dataIc", JSON.stringify(responseIc));
        this.$router.push(`/sales/serah-terima/print/null`);
      }
    },
    getDeliveryOrderNumber(valueSearch = ""): void {
      const params = new RequestQueryParams();
      if (valueSearch) params.search = `documentNumber~*${valueSearch}*`;
      if (valueSearch && this.customerId)
        params.search += `_AND_customer.secureId~${this.customerId}`;
      else if (this.customerId)
        params.search = `customer.secureId~${this.customerId}`;
      this.loading.do = true;
      salesOrderServices
        .getDeliveryOrderForBAST(params)
        .then(response => {
          this.dataDeliveryOrderNumber = response.data;
        })
        .finally(() => (this.loading.do = false));
    },
    getInternalContractNumber(valueSearch = ""): void {
      const params = new RequestQueryParams();
      if (valueSearch) params.search = `${valueSearch}`;
      this.loading.icNumber = true;
      salesOrderServices
        .getListInternalContractSo(params)
        .then(response => {
          this.dataInternalContractNumber = response.data;
        })
        .finally(() => (this.loading.icNumber = false));
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.onSubmit(false);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.onSubmit(false);
    },
    assignSearch(key, value, and): string {
      if (key === "deliveryOrderNumber" && value)
        return and + `documentNumber~${value}`;
      else if (key === "customerName" && value)
        return and + `customer.secureId~${this.customerId}`;
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    mapingDataSource(response) {
      this.dataSource = response.data.map((res, index) => {
        return {
          ...res,
          no: setNumbering(this.page - 1, this.limit, index),
          key: setNumbering(this.page - 1, this.limit, index),
        };
      });
    },
    onSubmit(firstSearch = true): void {
      this.form.validateFields((err, values) => {
        if (err) return;
        const params = new RequestQueryParams(
          "",
          this.page - 1,
          "",
          this.limit
        );

        if (firstSearch) {
          params.page = 0;
          this.page = 1;
        }

        this.loading.find = true;
        if (this.isTypeIC) {
          if (values.internalContractNumber)
            params.search = values.internalContractNumber;
          salesOrderServices
            .getListInternalContractSo(params)
            .then(response => {
              this.totalData = response.totalElements;
              this.mapingDataSource(response);
            })
            .finally(() => (this.loading.find = false));
        } else {
          params.search = this.dynamicSearch(values);
          salesOrderServices
            .getListDoReadyToCreateBast(params)
            .then(response => {
              this.totalData = response.totalElements;
              this.mapingDataSource(response);
            })
            .finally(() => (this.loading.find = false));
        }
      });
    },
  },
});
