var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_print_minutes_of_handover") } },
    [
      _c(
        "a-card-grid",
        { staticClass: "w-100", attrs: { hoverable: false } },
        [
          _c(
            "a-form",
            _vm._b(
              {
                attrs: {
                  layout: "vertical",
                  form: _vm.form,
                  labelAlign: "left"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.onSubmit.apply(null, arguments)
                  }
                }
              },
              "a-form",
              _vm.formItemLayout,
              false
            ),
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t(_vm.formRules.referenceType.label) } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRules.referenceType.decorator,
                          expression: "formRules.referenceType.decorator"
                        }
                      ],
                      attrs: {
                        name: _vm.formRules.referenceType.name,
                        placeholder: _vm.$t(
                          _vm.formRules.referenceType.placeholder
                        ),
                        allowClear: true
                      },
                      on: { change: _vm.handleChangeReferenceType }
                    },
                    _vm._l(_vm.REFERENCE_TYPES, function(data) {
                      return _c(
                        "a-select-option",
                        { key: data.key, attrs: { value: data.value } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")
                              ]),
                              _vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.isTypeDO
                ? [
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          label: _vm.$t(_vm.formRules.deliveryOrderNumber.label)
                        }
                      },
                      [
                        _c(
                          "a-select",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value:
                                  _vm.formRules.deliveryOrderNumber.decorator,
                                expression:
                                  "formRules.deliveryOrderNumber.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.deliveryOrderNumber.name,
                              placeholder: _vm.$t(
                                _vm.formRules.deliveryOrderNumber.placeholder
                              ),
                              showSearch: true,
                              "filter-option": false,
                              loading: _vm.loading.do,
                              allowClear: true
                            },
                            on: {
                              search: _vm.getDeliveryOrderNumber,
                              change: _vm.handleChangeDo
                            }
                          },
                          _vm._l(_vm.dataDeliveryOrderNumber, function(
                            data,
                            index
                          ) {
                            return _c(
                              "a-select-option",
                              {
                                key: index,
                                attrs: { value: data.documentNumber }
                              },
                              [
                                _c(
                                  "a-tooltip",
                                  [
                                    _c("template", { slot: "title" }, [
                                      _vm._v(
                                        " " + _vm._s(data.documentNumber) + " "
                                      )
                                    ]),
                                    _vm._v(
                                      " " + _vm._s(data.documentNumber) + " "
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          label: _vm.$t(_vm.formRules.customerName.label)
                        }
                      },
                      [
                        _c("SelectCustomer", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.customerName.decorator,
                              expression: "formRules.customerName.decorator"
                            }
                          ],
                          attrs: { name: _vm.formRules.customerName.name },
                          on: { change: _vm.handleChangeCustomer }
                        })
                      ],
                      1
                    )
                  ]
                : _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(
                          _vm.formRules.internalContractNumber.label
                        )
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value:
                                _vm.formRules.internalContractNumber.decorator,
                              expression:
                                "formRules.internalContractNumber.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.internalContractNumber.name,
                            placeholder: _vm.$t(
                              _vm.formRules.internalContractNumber.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.icNumber,
                            allowClear: true
                          },
                          on: { search: _vm.getInternalContractNumber }
                        },
                        _vm._l(_vm.dataInternalContractNumber, function(
                          data,
                          index
                        ) {
                          return _c(
                            "a-select-option",
                            {
                              key: index,
                              attrs: { value: data.transactionNumber }
                            },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s("" + data.transactionNumber) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s("" + data.transactionNumber) +
                                      " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
              _c(
                "a-form-item",
                { attrs: { "wrapper-col": { span: 8, offset: 4 } } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading.find,
                        "html-type": "submit"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticClass: "w-100", attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("TableCustom", {
                    attrs: {
                      dataSource: _vm.dataSource,
                      columns: _vm.columnsTable,
                      paginationcustom: true,
                      defaultPagination: false,
                      loading: _vm.loading.find,
                      scroll: { x: "calc(700px + 50%)" }
                    },
                    on: { "on-view": _vm.responseViewTable }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("Pagination", {
                    attrs: {
                      total: _vm.totalData,
                      pageSizeSet: _vm.limit,
                      idPagination: "pagination1"
                    },
                    on: {
                      "response-pagesize-change": _vm.responsePageSizeChange,
                      "response-currentpage-change":
                        _vm.responseCurrentPageChange
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12, align: "end" } },
                [
                  _c(
                    "a-tag",
                    {
                      staticStyle: { "font-size": "13px" },
                      attrs: { color: "grey" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("lbl_total_found")) +
                          " : " +
                          _vm._s(_vm.totalData) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }